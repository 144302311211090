.event-main{
    height: 100%;
    .event-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .table{
            margin: 2rem;
            display: flex;
            justify-content: center;
            width: 70%;
        }
    }
}