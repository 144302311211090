.userCard-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
    height: 3.3rem;
    gap: 1rem;
    border-radius: 2rem;
    width: fit-content;
    padding: .6rem 2rem .6rem .4rem;
    background-color: #E5F4FF;
    cursor: pointer;
    
    .left{
        display: flex;
        align-items: center;
        justify-content: center;

        .image{
            height: 2.5rem;
            width: 2.5rem;
        }
    }

    .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        p{
            margin: 0;
            padding: 0;
        }

        .name{
            font-size: .9rem;
            font-weight: 500;
        }

        .email{
            font-size: .8rem;
            color: #6C757D;
        }
    }
}