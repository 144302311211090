.vendor-login-container{
    background-color: #F8F8F8;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .vendor-login-main{
        margin: 5rem;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding: 2rem;
        width: 1000px;
        height: 550px;
        box-shadow: 0px 0px 20px #00000038;

        .left{
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
            width: 80%;

            .er_msg{
                color: red;
                padding: 0;
                margin: 0;
            }
            
            h1{
                color: #125488;
                margin-bottom: 3rem;
            }
            
            form{
                width: 100%;
                height: 100%;

                .input{
                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    width: 100%;
    
                    .ip-icon{
                        position: absolute;
                        color: #125488;
                        transform: translate(7px);
                        margin-bottom: 6px ;
                        transition: .2s;
                    }
    
                    input:focus + .ip-icon{
                        color: #125488;
                    }
                }

                .spclInput{
                    margin:15px 5px;

                    input{
                        padding-left: 30px ;
                    }
                    label{
                        margin-left: 30px;
                    }
                }

                .pass{
                    margin:15px 5px;

                    input{
                        padding-left: 30px ;
                    }
                    label{
                        margin-left: 30px;
                    }

                }

                

                .login-btn{
                    display: block;
                    margin: auto;
                    height: 45px;
                    width: 250px;
                    border-radius: 10px;
                    border: none;
                    background-color: #125488;
                    color: white;
                    font-weight: bolder;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: .2s;
                    font-size: 15px;
                }

                a{
                    text-decoration: none;
                    color: #125488;
                    float: right;
                    display: flex;
                    width: 100%;
                    justify-content: end;
                    margin-bottom: 2rem;
                }
                
                a:hover{
                    text-decoration: underline;
                }

                .login-btn:hover{
                    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.285);
                }
            }


            .separator {
                display: flex;
                align-items: center;
                text-align: center;
                color: #1a3f73; 
                font-weight: bold;
                margin: 2rem;

                span:after,
                span:before{
                    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
                    text-decoration:line-through;
                    margin: 1rem;
                }
            }

        }

        .right{
            width: 70%;
            height: 100%;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.216);
            display: flex;
            gap: 1rem;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            color: #125488;

            img{
                height: 300px;
            }

            h2{
                margin: 0;
            }

            h4{
                font-weight: lighter;
            }

           a{
            width: 100%;
            text-decoration: none;

            .register-btn{
                width: 50%;
                height: 40px;
                border-radius: 5px;
                border: 2px solid #125488;
                background:transparent;
                color: #125488;
                font-weight: bold;
                cursor: pointer;
                transition: .4s;
                display: block;
                margin: auto;
            }

            .register-btn:hover{
                background-color: #125488;
                color: white;
            }
           }


        }
    }
}