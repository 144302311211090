.eventCard-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem;
    font-family: poppins;
    width: 40rem;
    height: 20rem;
    padding: 1rem 2rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.171);
    border-radius: 2rem;
    cursor: pointer;
    transition: .3s;

    .top{
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0;

        h1{
            margin: 0;
            font-size: 1.7rem;
        }

        .location{
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .6rem;

            .location-icon{
                color: #125488;
            }
        }
    }

    .desc{
        margin: 1rem 0;
        width: 100%;
        height: auto;

        p{
            font-size: 1rem;
        }
    }

    .bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;

        .tags{
            display: flex;
            justify-content: flex-start;
            width: fit-content;

            .tag{
                background-color: #E5F4FF;
                margin-right:.5rem;
                border-radius: .5rem;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;

                p{
                    font-size: .9rem;
                    color: #125488;
                    margin: .4rem;
                }
            }
        }

        .date{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: .5rem;
            width: fit-content;
            margin: 0;
            padding: 0;

            .calendar-icon{
                height: 1rem;
            }

            p{
                font-size: .9rem;
            }
        }
    }
}

.eventCard-main:hover{
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.271);
    transform: scale(1.02);
}