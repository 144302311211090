.spclDropDown{
    position: relative;
    height: 50px;
    width: 100%;
    margin: 0;
    padding: 0;
  
    label{
        outline: none;
        width: 100%;
        position: absolute;
        color: #A5A5A5;
        font-size: .8rem;
        top: 0;
        margin: 15px;
        transition: .4s;
    }
  
    select{
        top: 0;
        width: 100%;;
        position: absolute;
        padding: 0 0 10px 12px;
        border: none;
        border-bottom: 1.6px solid #125488;
        background: transparent;
        margin: 16px 0;
        z-index: 1011;
        transition: .1s ease;
        font-size: .8rem;
        color: #125488;

    }  
    option:disabled{
        color: grey;
    }

    option[selected]{
        background-color: #125488;
        color: white;
    }
  
    select:focus{
        outline: none;
    }
  
    select:focus + label,
    select:valid + label{
        color: #125488;
        transform: translate(-0px,-20px);
  
    }
  }