.window-main{
    width: 40rem;
    position: sticky;
    top: 0;
    z-index: 100;
    margin: 0 ;


    .null-container{
        width: 100%;
        margin: 0 1rem;
        box-shadow: 0 0 5px 0 #00000033 ;
        border-radius: 2rem;
        background-color: #E5F4FF;
            height: 40rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        h1{
            color: #12558834;
            font-weight: 400;
        }

        img{
            height: 20rem;
        }

    }

    .window-container{
        width: 40rem;
        margin: 0 1rem;
        box-shadow: 0 0 5px 0 #00000033 ;
        border-radius: 2rem;
        background-color: #E5F4FF;
        height: 40rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
    
        .top{
            display: flex;
            top: 0;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            padding: 1rem;
            border-bottom: 4px solid white;
    
            .left{
                width: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 2rem;
    
                .profile{
                    margin: 0;
                    height: 3.5rem;
                    width: 3.5rem;
                    border-radius: 50%;
                }
        
                .user-name{
                    font-weight: bold;
                    font-size: 1.3rem;
                    font-family: poppins;
                }
    
            }
            .close-icon{
                cursor: pointer;
            }
        }
    
        .area{
            height: 75vh;
            display: flex;
            flex-direction: column-reverse;
            .chat-area{
                display: flex;
    
                .quotation{
                    width: 80%;
                    background-color: white;
                    padding: .5rem 1rem;
                    border-radius: 1rem;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.288);
                    margin: 1rem;
    
                    h3{
                        color: #125488;
                        font-weight: 900;
                        font-size: 1.5rem;
                        padding: 0;
                        margin: 0;
                    }
    
                    .bottom{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
    
                        .see-more , .download{
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            color: #125488;
                            font-family: poppins;
                            font-weight: 2rem;
                            font-size: .8rem;
                            cursor: pointer;
                        }
                    }
                }
    
                .btns{
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin: 1.3rem 1rem;
    
                    button{
                        width: 6rem;
                        height: 2rem;
                        border-radius: .5rem;
                        border: none;
                        cursor: pointer;
                    }
    
                    .hold-btn{
                        background-color: #125488;
                        color: white;
                        font-family: poppins;
                    }
    
                    .cancel-btn{
                        background-color: black;
                        color: white;
                        font-family: poppins;
                    }
                }
            }
        }
    
        .message-area{
            background-color: white;
            height: 4rem;
            border-bottom-right-radius: 2rem;
            border-bottom-left-radius: 2rem;
            
            form{
                display: flex;
                justify-content: space-between;
    
                input{
                    width: 100%;
                    height: auto;
                    border: none;
                    background-color: transparent;
                    padding: 1rem;
                    font-size: 1rem;
                    font-family: poppins;
    
                }
        
                input:focus{
                    outline: none;
                }
        
                button{
                    background: none;
                    border: none;
                    padding: 1rem;
                    cursor: pointer;
                    transition: .3s;
                }
    
            }
    
        }
    }
}