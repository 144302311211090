.nav-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  gap: 2rem;
  height: 80px;
  background-color: #F8F8F8;
  border-bottom: 1px solid #D9D9D9;
  font-family: poppins;

  .logo{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    width: 20%;
    color: #2998FF;
    cursor:default;
  }

  .loc{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    color: #125488;
    gap: .5rem;
    cursor: pointer;

    .loc-icon{
      color: #00BABA;
    }
  }

  .loc:hover{
    h4{
      text-decoration: underline;
    }
  }

  .search{
    background-color: #E5F4FF;
    width: 50%;
    border-radius: 3rem;
    padding: .3rem .5rem;
    

    form{
      display: flex;
      justify-content: space-between;

      input{
        border: none;
        background: transparent;
        width: 90%;
        padding: 0 1rem;
        font-family: poppins;
      }

      input:focus{
        outline: none
      }

      input::placeholder{
        font-family: poppins;
      }

      button{
        border-radius: 50%;
        height: 45px;
        width: 45px;
        background-color: #125488;
        border: none;
        margin: 0;
        cursor: pointer;
      }

      .sub-icon{
        color: white;
      }
      
    }
  }

  .btns{
    width: 20%;
    display: flex;
    justify-content:space-evenly;
    gap: 1rem;
    align-items: center;

    a{
      text-decoration: none;
      color: white;
      margin: 0;
      padding: 0;

      .vendor-btn{
        background-color: transparent;
        border: none;
        color: #125488;
        font-weight: 600;
        font-family: poppins;
        cursor: pointer;
      }
    }

    .vendor-btn:hover{
      text-decoration: underline;
    }

    .login-btn{
      background-color: #125488;
      color: white;
      height: 2.5rem;
      width: 7rem;
      border-radius: 2rem;
      border: none;
      font-weight: 600;
      font-family: poppins;
      cursor: pointer;

      a{
        text-decoration: none;
        color: white;
      }
    }

    .login-btn:hover{
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);      }
  }
}