.eventListing-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;

    .grid{
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit,minmax(43rem,1fr));   
        gap: 1rem;
    }
}