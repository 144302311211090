.dashboard-main{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: white;
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;

    .backButton{
        display: flex;
        align-items: center;
        gap: .5rem;
        color: #125488;
        margin: 2rem 0 0 2rem;

        a{
            color: #125488;
        }
    }

    .info{
        display: flex;
        align-items: center;
        gap: 1rem;
        color: #125488;
        margin: 2rem;

        img{
            height: 5rem;
            width: 5rem;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
        }
        h3{
            font-weight: 500;
        }
    }

    .menu{
        background-color: #E5F4FF;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        ul{
            list-style-type: none;
            display: flex;
            justify-content: center;
            gap: 3rem;

            li{
                a{
                    color: #125488;
                    text-decoration: none;
                    position: relative;
                }

                a::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    background-color: #125488;
                    transition: width 0.3s ease, left 0.3s ease;
                  }
                  
                  a:hover::after {
                    width: 100%;
                    left: 0;
                  }

                  .active::after{
                    width: 100%;
                    left: 0;
                  }
            }
        }
    }
}