.fp-container{
    background-color: #F8F8F8;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    .main{
        margin: 5rem;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding: 3rem;
        width: 1000px;
        height: 550px;
        box-shadow: 0px 0px 20px #00000038;

        .left{
            width: 80%;
            height: 100%;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.216);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: .6rem;
            color: #125488;
            
            img{
                height: 350px;
            }

            
            a{
                text-decoration: none;
                width: 80%;
                height: 2.4rem;

                button{
                    font-weight: bold;
                    font-size: 15px;
                    color: #125488;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    border: 2px solid #125488;
                    background:transparent;
                    cursor: pointer;
                    transition: .4s;
                    display: block;
                    margin: auto;
                }
            }

            a:hover{
                button{
                    background-color: #125488;
                    color: white;
                }
            }

        }

        .right{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 2rem;
            padding: 1rem;
            width: 100%;
            height: 100%;

            h1{
                font-weight: 500;
                color: #125488;
            }

            form{
                width: 75%;
                
                button{
                    background-color: #2998FF;
                    color: white;
                    border: none;
                    width: 100%;
                    display: block;
                    margin: 1rem auto;
                    height: 2.5rem;
                    border-radius: 10px;
                    cursor: pointer;
                    font-weight: bold;
                }
            }

            a{
                color: #125488;
                text-decoration: none;
            }

            a:hover{
                text-decoration: underline;
            }

            .bottom-link{
                margin-right: 1rem;
                display: flex;
                align-items: center;
                gap: .5rem;
                .arrow-icon{
                    color: #125488;
                }
            }
        }
    }
}