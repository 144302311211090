.comments-main{
    right: 0;
    width: 100%;
    padding: 1rem;
    width: 40rem;
    border-radius: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: .4s; 
    background-color: #F3F3F3;

    .com-main-top{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-left: 1rem;
        position: relative;
        padding: 0;

        .close{
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }

        .close:hover{
            background-color: rgba(0, 0, 0, 0.1);
        }

    }





    .main-top{
        display: flex;
        justify-content: space-between;
        align-self: center;
    }

    
    form{
        position: relative;

        textarea{
            width: 90%;
            height: 8rem;
            resize: none;
            border: 1px solid #ccc;
            border-radius: 0.5rem;
            padding: 0.5rem;
            margin: 1rem;
            font-family: poppins;
        }

        textarea:focus{
            outline: none;
        }

        button{
            height: 2rem;
            width: 5rem;
            background-color: #125488;
            color: white;
            border: none;
            font-family: poppins;
            border-radius: 1rem;
            margin: 0 1rem;
            cursor: pointer;
           
        }
    }
}