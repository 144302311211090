.aa-container{
    width: 10rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.445);
    height: 6rem;
    background-color: white;
    font-family: poppins;
    border-radius: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 4.5rem;
    margin-right: 2rem;
    z-index: 1;

    .row1{
        color: #125488;
        margin: 0;
        width: 100%;
        height: 100%;
        border-top-right-radius:1rem;
        border-top-left-radius:1rem;
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        cursor: pointer;
        transition: .4s;
    }
    .row2{
        color: #125488;
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        border-bottom-right-radius:1rem;
        border-bottom-left-radius:1rem;
        gap: 1rem;
        align-items: center;
        cursor: pointer;
        transition: .4s;
    }

    .row1:hover,.row2:hover{
        background-color: rgba(0, 0, 0, 0.081);
    }
}