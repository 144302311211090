
.table-container{
  width: 80%;
  box-shadow: 0px 0px 4px #00000013;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 2rem;


  .top{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;

    h1{
      color:#125488;
      font-weight: 600;
    }

    .search{
      position: relative;

      input[type=search]{
        position: relative;
        background-color: #E5F4FF;
        height: 2.4rem;
        width:20rem;
        color: #A5A5A5;
        border: none;
        border-radius: .7rem;
        padding: 1rem;
        padding-left: 2rem;
        font-family: poppins;
      }
  
      input:focus{
        outline: none;
        color: black;
      }

      .search-icon{
        position: absolute;
        z-index: 1;
        color: #A5A5A5;
        margin-top: .6rem;
        margin-left: .5rem;
      }

    }

    
    .right{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .sort-by{
        position: relative;
        display: flex;
        p{
          position: absolute;
          width: 50%;
          margin-top: 2.45rem;
          left: 1rem;
          z-index: 1000;
          
          .sort-label {
            color: grey;
            font-size: .9rem;
          }
          
          .filter-value {
            color: black;
          }
        }
  
        select{
          position: relative;
          height: 2.4rem;
          background-color: #E5F4FF;
          border: none;
          border-radius: .7rem;
          padding-left: 4.5rem;
          width: 100%;
          font-family: poppins;
          margin-top: 2rem;
          cursor: pointer;
        }
  
        select:focus{
          outline: none;
        }
      }

      .add-btn{
        position: relative;
        width: 100%;

        .add-icon{
          position: absolute;
          z-index: 1;
          color: white;
          margin-top: .6rem;
          margin-left: 1rem;

        }
        button{
          position: relative;
          height: 2.4rem;
          background-color: #E5F4FF;
          border: none;
          border-radius: .7rem;
          padding: 0 1rem ;
          width: 100%;
          background-color: #2998FF;
          color:white;
          font-family: poppins;
          cursor: pointer;
        }
      }
    }
  }

  table{
    font-family: poppins;
    width: 80%;
    transition: .4s;

    thead{

      th{
        font-weight: 500;
        text-align: left;
        color: #A5A5A5;
        padding: 1rem 0;  
        border-bottom: 1px solid #EEEEEE;
      }

      .action-th{
        text-align: center;
      }
    }

    tbody{
      td{
        border-bottom: 1px solid #EEEEEE;
        padding: 1rem 0;  
      }

      .btn-td{
        text-align: center;

        button{
          height: 1.8rem;
          width: 4rem;
          margin-right: 1rem;
          border-radius: .3rem;
          border: none;
          cursor: pointer;
          transition: .2s;
          color: white;

        }

        button:hover{
          transform: scale(1.1);
        }

        button:focus{
          outline: none;
        }

        .view-btn{
          background-color: black;
        }

        .edit-btn{
          background-color: #125488;
        }

        .delete-btn{
          background-color: #C90C0C;
        }
      }
    }
  }

  .pagination{
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: poppins;

    .page-btn{
      margin: 1rem;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      border: none;
      cursor: pointer;

    }

    .active{
      background-color:#2998FF;    
      color: white;  
    }
  }

}