.fileInput{
    display: inline;
    position: relative;
    cursor: pointer;
    width: 100%;

    .main-label{
      font-size: .8rem;
      color: #125488;
      margin: 1rem;
      font-weight: 600;
    }
    
    .icon{
      align-items: center;
      display: flex;
      justify-content: flex-start;
      border: 2px solid #125488;
      width: 100%;
      height: 3rem;
      border-radius: .9rem;
      position: relative;

      .imgIcon{
        color: rgba(128, 128, 128, 0.475);
        margin-left: 1rem;
      }

      label{
        margin-left: 1rem;
        color: rgba(128, 128, 128, 0.475);
        font-size: .8rem;
      }

      .checkIcon{
        position: absolute;
        right: 0;
        margin-right: 1rem;
        color: rgba(128, 128, 128, 0.475);
      }

      .success{
        color: green;
      }
    }   

    
    input[type=file]{
      position: absolute;
      cursor: pointer;
      opacity: 0;
      top: 0;
      left: 0;
      margin: 1rem;
      width: 100%;
      height: 100%;
      box-shadow:none;
      background-color: none;
    }
  }