.evEx-main{
    display: flex;
    justify-content: center;
    gap: .2rem;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.85);
    position: absolute;
    top: 0;
    left: 0;
    padding: 4rem 0;
    width: 100%; 
    min-height: 100%; 
    z-index: 1000;
    transition: .4s; 

    .close-icon{
        z-index: 10001;
        position: absolute;
        top: 0;
        right: 0;
        margin: 2rem;
        color: white;
        height: 1rem;
        cursor: pointer ;
    }

    .evEx-container{
        background-color: #F3F3F3;
        border-radius: 2rem;
        padding: 1rem 0 3rem 0;
        font-family: poppins;
        display: flex;
        flex-direction: column;
        height: auto;
        overflow: visible;
        width: 100%;
        transition: .4s; 

        
        .nav-top{
            display: flex;
            top: 0;
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
            z-index: 1;
            position: sticky;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);   
            padding: 0 6rem 1rem 6rem;
            background-color: #F3F3F3;
            margin: 0;
    
            .left{
                display: flex;
                gap: 1rem;
                align-items: center;
                cursor: pointer;
                .profilePic{
                    height:3rem;
                    width:3rem;
                    border-radius: 50%;
                    margin: 0;
                }
    
                .userName{
                   font-family: poppins;
                   font-weight: 600; 
                }
            }
            
            .right{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .connect-btn{
                    height: 2rem;
                    width: 6rem;
                    border-radius: 1rem;
                    border: none;
                    background-color: #125488;
                    color: white;
                    font-family: poppins;
                    cursor: pointer;
                    
                }
            }
        }
    
        .content{
            padding: 0 6em;
            .header{
                margin: 1rem 0;
                .event-title{
                    margin: 0;
                    font-weight: 600;
                }
    
                .event-location{
                    margin: 0;
                }
            }
    
            .imageSection{
                .mainSection{
                    display: flex;
                    justify-content: center;
                    gap: 2rem;
    
                    .main-img{
                        width: 55rem;
                        border-radius: 1rem;
                    }
    
                    .btns{
                        list-style-type: none;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        margin: 0;
    
                        li{
                            margin: 1rem 0;
                            font-size: 1.2rem;
                            background-color: white;
                            height: 2rem;
                            width: 2rem;
                            padding: .3rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
                            cursor: pointer;
                            transition: .2s;

                        }
                        .like{
                            transition: .4s;
                        }
                        
                        .liked{
                            background-color: red;
                            color: white;
                        }
                 
    
                        li:hover{
                            transform: scale(1.1);
                        }
                    }
                }
    
                .imageList{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;
                    margin: 1rem 0;
                    .img{
                        width:4rem;
                        height:2.5rem;
                        border-radius: .1rem;
                        cursor: pointer;
                        transition: .2s;
                    }
    
                    .selected{
                        transform: scale(1.2);
                    }
    
                    .img:hover{
                        transform: scale(1.1);
                    }
                }
            }
    
            .description{
                margin: 1rem 0;
            }
    
            .related{
                .flex{
                    display: grid;
                    align-items: center;
                    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));   
                    gap: 1rem;
                    margin: 2rem 0;
                    justify-content: center;
    
                    img{
                        height: 90%;
                        width: 80%;
                        border-radius: .5rem;
                        cursor: pointer;
                    }
                }
    
            }
        }
    }
}