.dp-main{
    color: #125488;
    padding: 2rem;
  
    .top{
      display: flex;
      justify-content: center;
      gap:2rem;
  
      .left,.right{
        margin: 1rem;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;

        .file-input{
            width: 31rem;
        }
  
  
        input,textarea{
          padding: .5rem;
          width: 30rem;
          height: 3rem;
          border: none;
          font-size: .9rem;
          font-family: poppins;
          background: none;
          box-shadow: 0px 0px 2px #2625254b;
          border-radius: 1rem;
        }
  
        input::placeholder,textarea::placeholder{
          color: rgba(128, 128, 128, 0.475);
        }
  
        textarea{
          height: 7rem;
          resize: none;
        }
  
        input:focus,textarea:focus{
          outline: none;
        }
      }
    }
    .btns{
        margin: 1rem 7rem;
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
    
        button{
            border: none;
            height: 2.2rem;
            width: 6.5rem;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
            border-radius: .5rem;
            font-family: poppins;
            cursor: pointer;
            transition: .2s;
        }
    
        button:hover{
          transform: scale(1.02);
        }

        .blue-btn{
          background-color: #125488;
          color: white;
        }

        .white-btn{
            background-color: white;
            color: #125488;
        }
      }

    .bottom{
      text-align: left;
      width: 100%;

      .add-details{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem; 
        width: 100%;
        height: 2rem;
        transition: .3s;
        margin: 2rem;

        .accordion-header{
            display: flex;
            justify-content: space-between;
            width: 90%;
            padding: 0 2rem;
            align-items: center;
            border-bottom: 2px solid #B5B7C0;
            cursor: pointer;

            .down{
                transition: .2s;
            }

            h3{
                margin: 0;
                padding: .8rem;
            }
        }

        .accordion{ 
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
        }
    }

    .expand{
        height: auto;
        transition: .4s;
        margin: 1rem;

        .up{
            transition: .4s;
            transform: rotate(180deg);
        }
        .links{
            margin: 0rem 3rem;
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fit,minmax(500px,2fr));   
            gap: 3rem;
    
          .link{
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            height: 2.5rem;
            padding: 1rem;
            gap: 1rem;
            border-radius: 1rem;
            box-shadow: 0px 0px 2px #7272726c;
            transition: .4s;
          }

          .icon{
            color: #A5A5A5 ;
          }

          .active{
            color: green;
          }
        }
    }

  
      h3{
        margin-left: 2rem;
      }
  
    }
  }