.sign-container{
    background-color: #F8F8F8;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .sign-main{
        margin: 5rem;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding: 2rem;
        width: 1000px;
        height: 600px;
        box-shadow: 0px 0px 20px #00000038;

        .left{
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
            width: 80%;
            
            h1{
                color: #125488;
                top: 0;
                margin-bottom: 2rem;
            }

            form{
                width: 100%;
                height: 100%;

                .name{
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2rem;
                    width: 100%;
    
                    input{
                        padding-left: 16px;
                        width: 90%;
                    }

                }

                .signUp-btn{
                    display: block;
                    margin: 1rem auto;
                    height: 45px;
                    width: 250px;
                    border-radius: 10px;
                    border: none;
                    background-color: #125488;
                    color: white;
                    font-weight: bolder;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: .2s;
                    font-size: 15px;

                }

                .signUp-btn:hover{
                    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.285);
                }
                .TnC{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    color: #125488;
                    margin: 1rem auto;

                    input[type="checkbox"]{
                        margin: .2rem .2rem 0 0 ;
                    }
    
                    a{
                        color: #125488;
                    }
                }

                .err_msg{
                    color: red;
                    text-align: center;
                    width: 100%;
                    font-size: .8rem;
                    display: block;
                    margin: auto;
                }
            }



            .separator {
                display: flex;
                align-items: center;
                text-align: center;
                color: #1a3f73; 
                font-weight: bold;
                margin: 2rem;

                span:after,
                span:before{
                    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
                    text-decoration:line-through;
                    margin: 1rem;
                }
            }

            .pass{
                .p-icon{
                    margin-right: 15px;
                }
            }

        }

        .right{
            width: 70%;
            height: 100%;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.216);
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            padding:0;
            color: #125488;

            img{
                height: 300px;
                margin: 0;
            }

            h2{
                margin: 0;
                padding: 0;
            }

            h4{
                font-weight: lighter;
                padding: 0;
            }

            a{
                width: 100%;
                text-decoration: none;

                .login-btn{
                    width: 50%;
                    display: block;
                    margin: auto;
                    height: 40px;
                    border-radius: 5px;
                    border: 2px solid #125488;
                    background:transparent;
                    color: #125488;
                    font-weight: bold;
                    cursor: pointer;
                    transition: .4s;
                }
    
                .login-btn:hover{
                    background-color: #125488;
                    color: white;
                }
            }



        }
    }
}