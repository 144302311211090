.list-container{
    width: 45rem;
    margin: 0 ;
    box-shadow: 0 0 5px 0 #00000033 ;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #E5F4FF;
    height: auto;

    h1{
        color: #125488;
    }

    .links{
        width: 100%;
        display: flex;
        justify-content: center;
        ul{
            display: flex;
            list-style: none;
            gap: 4rem;
            cursor: pointer;

            li{

                a{
                    text-decoration: none;
                    color: black;
                    text-decoration: none;
                    padding: 5px;
                    display: inline-block;
                    position: relative;
                }

                a::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background-color: #125488; 
                    transition: width 0.3s ease; 
                }

                a:hover::after {
                    width: 97%; 
                }

                a:hover{
                    color: #125488;

                }

                .active {
                    color: #125488; 
                }

                .active::after {
                    width: 100%; 
                    background-color: #125488; 
                }

            }
        }
    }
}