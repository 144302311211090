.comments-container{
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.445);
    font-family: poppins;

    .com-top{
        width: 6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img{
            height: 2.4rem;
            border-radius: 50%;
        }

        h4{
            font-weight: 400;
        }
    }

    .com-content{
        p{
            font-size: .9rem;
            font-weight: 300;
        }
    }
}