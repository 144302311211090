.dcd-main{
    align-items: center;
    padding: 0rem 3rem;
    font-family: poppins;
    margin: 0;
  
    .desc{
      height: 8rem;
      font-family: poppins;
      width: 97%;
      margin: 2rem 0;
      border: 1px solid #2323232d;
      padding: 1rem;
      font-size: .9rem;
      word-break: break-word;
      border-radius: 1rem;
      resize: none;
    }
  
    .desc::placeholder{
      font-size: .9rem;
      color: #23232359;
      font-family: poppins;
    }
  
    .desc:focus{
      outline: none;
    }
  
    ul{
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: left;
      list-style-type: none;
      cursor: pointer;
      transition: .4s;
  
      .main-list{
        border-bottom: 1px solid #2323232d;
        padding: .7rem;
        
        .list-flex{
          display: flex;
          align-items: center;
          gap: 1rem;
  
          label{
            position: relative;
            cursor: pointer;
            color: #125488;
            font-size: .9rem;
          }
          .icon{
            color: #125488;
          }
        }

        select{
          width: 100%;
          height: 2rem;
          border: none;
          margin:0;   
          padding-right: 1rem;   
          font-family: poppins;
          font-size: 1rem;
          color:#125488;
          cursor: pointer;
          background-color: transparent;
        }
  
        select:focus{
          outline: none;
        }

        .loc-btn{
          background:none;
          border: none;
          height: 1rem;
          color: #125488;
          margin-bottom: .8rem;
          font-size: 1rem;
          font-family: poppins;
          cursor: pointer;
        }
      }
    }

    .btns{
      margin: 3rem 0;
      display: flex;
      justify-content: right;
      gap: 2rem;
  
      button{
          background-color: white;
          color: #125488;
          border: none;
          height: 2.4rem;
          width: 7rem;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
          border-radius: .3rem;
          font-family: poppins;
          cursor: pointer;
          transition: .2s;
      }
  
      button:hover{
        transform: scale(1.1);
      }
      .p-btn{
        background-color: #125488;
        color: white;
      }
  
    }
  }