.contact-container{
    display: flex;
    align-items: center;
    width: 90%;
    height: 4rem;
    border: 2px solid #F8F8F8;
    background-color: white;
    box-shadow: 0 0 5px #00000034;
    font-family: poppins;
    border-radius: 1rem;
    padding: 0 1rem;
    margin: .8rem;
    cursor: pointer;

    img{
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
    }

    .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0;
        padding-left: 1rem;

        .user-name{
            color: #125488;
            font-weight: 500;
            font-size: 1rem;
            margin: 0;
        }

        .amount{
            color: #4285F4;
            font-size: .8rem;
            margin: 0;

        }
    }
}

.contact-container:hover{
    background-color: #F8F8F8;
}