.es-container{
    h1{
        text-align: center;
        color: #125488;
    }
    .flex{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 2rem;
    }
}