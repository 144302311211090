.cSl-container{
    margin: 2rem;
    font-family: poppins;
    display: flex;
    justify-content: center;
    align-items: center;

    ul{
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 2rem;
        white-space: nowrap;
        padding: 0;
        margin: 0;
        overflow-x: scroll;

        li{
            list-style: none;
            cursor: pointer;
            padding: .4rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .4s;

            a{
                text-decoration: none;
                color: black;
                font-weight: 500;
            }

            a:hover{
                color: rgb(88, 88, 88);
            }
        }

        li.active{
            background-color: #EBEAEA;
            border-radius: 5rem;
        }

    }

    
    ul::-webkit-scrollbar { 
        display: none; 
    }

    .arrowL,.arrowR{
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        margin: 2rem;
        padding: 1rem;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        cursor: pointer;
    }

    .arrowL{
        justify-content: flex-start;
    }

    .arrowR{
        justify-content: flex-end;
    }
}