.feed-main{
    height: 100%;
    background-color: transparent;
    .grid{
        margin: 1rem;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit,minmax(300px,1fr));   
        gap: 0rem;

        .upload-card{
            width: 300px;
            height: 300px;
            border: 2px dashed #404B52;
            border-radius: 20px;
            margin: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;

            p{
                color: #404B52;
            }

            button{
                height: 2.3rem;
                width: 6rem;
                background-color: #125488;
                color: white;
                margin-top: 1rem;
                border: none;
                cursor: pointer;
                border-radius: 2rem;
        }
        }
    }
}