.preview{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;

    .card{
      width: 43rem;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      border-radius: .5rem;

      .top{
        display: flex;
        justify-content: left;
        gap: 1rem;
        margin: .5rem 0;
        padding: .5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
        h2{
          margin: .5rem;
          font-size: 1rem;
        }
  
        img{
          border-radius: 50%;
          height: 2rem;
          width: 2rem;
        }
      }
      .con{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
  
        .mImg{
          width: 100%;
          height: auto;
          padding: 0;
          margin: 0;
        }
    
        p{
          font-weight: bolder;
          padding: 1rem .5rem;
          width: 96%;
          text-align: left;
        }
      }
    }

    .btn{
        display: flex;
        justify-content: flex-end;
        width: 43rem;

        button{
            border: none;
            height: 2.4rem;
            width: 7rem;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
            border-radius: .3rem;
            font-family: poppins;
            cursor: pointer;
            transition: .2s;
            margin: 1rem;
        }
    
        button:hover{
          transform: scale(1.1);
        }
    
        .btn-white{
            background-color: white;
            color: #125488;
        }
    
        .btn-blue{
            background-color: #125488;
            color: white;
        }
    }

}