.card-container{  
    margin: 2rem;
    font-family: poppins;
    width: 300px;
    height: 300px;
    cursor: pointer;

    .desc{
        font-weight: bold;
        margin-left: .3rem;
        width: 290px;
    }

    .card-main{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .card-top{
            width: 100%;
            height: 100%;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.348);
            position: absolute;

            .top{
                display: inline-flex;
                align-items: center;
                padding: .5rem 1rem;
                justify-content: center;
                gap: 1rem;
                color: white;
                
                .profilePic{
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                }
            }

        }
        
        .main-pic{
            object-fit: cover;
            height: max-content;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            transition: .4s;
        }

    }
}

.card-container:hover{
    .main-pic{
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.3);
    }
}