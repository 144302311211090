.pass{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    margin: 0;
    padding: 0;
  
    label{
        outline: none;
        width: 100%;
        position: absolute;
        color: #A5A5A5;
        font-size: .8rem;
        top: 0;
        margin: 15px 16px;
        transition: .4s;
    }
  
    input{
        top: 0;
        width: 100%;;
        position: absolute;
        padding-left: 16px;
        padding-bottom: 10px;
        border: none;
        border-bottom: 1.6px solid #125488;
        background: transparent;
        margin: 16px 0;
        width: 100%;
        z-index: 1011;
        transition: .1s ease;
        font-size: .8rem;
        font-weight: bold;
        color: #125488;
    }
  
    input:focus{
        outline: none;
    }
  
    input:focus + label,
    input:valid + label{
        color: #125488;
        transform: translate(-0px,-20px);
  
    }

    .p-icon{
        position: absolute;
        right: 0;
        color: #125488;
        z-index: 1012;
        cursor: pointer;
    }
  }