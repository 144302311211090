.create-main{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    padding: 4rem 0;
    width: 100%; 
    min-height: 100%; 
    z-index: 1000; 

    .close-icon{
        z-index: 10001;
        position: absolute;
        top: 0;
        right: 0;
        margin: 5rem;
        color: white;
        height: 1rem;
        cursor: pointer ;
    }
    
    .create-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: white;
        height:max-content;
        width: 70rem;
        margin: 6rem;
        border-radius: 3rem;
        z-index: 1000;

        h1{
            color: #125488;
            font-weight: 600;
            width: 100%;
            text-align: center;
            padding: .5rem 0;
            margin: 0 0 2rem 0;
            border-bottom: 2px solid #EEEEEE;
            cursor: pointer;
        }
    
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            gap: 1rem; 
    
            .ip{
                width: 30rem;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                align-items: start;
                justify-content: start;
                
                label,input{
                    width: 100%;
                }
    
                label{
                    color: #404B52;
                }
    
            }
    
            .form-input{
                width: 10rem;
                height: 1.9rem;
                border-radius: .6rem;
                padding: 0 1rem;
                border: none;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.123);
                font-family: poppins;
            }

            select{
                width: 32rem;
                height: 1.9rem;
                border-radius: .6rem;
                padding: 0 1rem;
                border: none;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.123);
                font-family: poppins;
            }

            .form-input:hover,.form-textarea:hover,select:hover{
                box-shadow: 0 0 3px 0 #2998FF;
            }

            .form-textarea:hover{
                box-shadow: 0 0 3px 0 #2998FF;

            }
    
            .form-input::placeholder,.form-textarea::placeholder{
                color: rgba(0, 0, 0, 0.123);
            }
    
            .form-input:focus,.form-textarea:focus,select:focus{
                outline: none;
                box-shadow: 0 0 3px 0 #2998FF;
            }
    
            .form-textarea{
                width: 30rem;
                height: 5rem;
                resize: none;
                font-family: poppins;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.123);
                padding: .3rem 1rem;
                border: none;
                border-radius: .6rem;
    
            }
    
            .add-details{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2rem; 
                width: 100%;
                height: 2rem;
                transition: .3s;
                margin: 2rem;
    
                .accordion-header{
                    display: flex;
                    justify-content: space-between;
                    width: 50rem;
                    padding: 0 2rem;
                    align-items: center;
                    border-bottom: 2px solid #B5B7C0;
                    cursor: pointer;
    
                    .down{
                        transition: .4s;
                    }
                }
    
                .accordion{ 
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    .ip{
                        width: 30rem;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        align-items: start;
                        justify-content: start;
        
                        label,input{
                            width: 100%;
                        }
                    }   
                }
            }
    
            .expand{
                height: auto;
                transition: .4s;
                margin: 1rem;
    
                .up{
                    transition: .4s;
                    transform: rotate(180deg);
                }
            }
        }
    
        .form-btns{
            display: flex;
            gap: 2rem;
            margin-bottom: 2rem;
            width: 30rem;
            justify-content: flex-end;
            button{
                width: 6rem;
                height: 2rem;
                border-radius: .5rem;
                border: none;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.123);
                font-family: poppins;
                cursor: pointer;
            }
    
            button[type=submit]{
                background-color: #125488;
                color: white;
            }
            button[type=reset]{
                background-color: white;
                color: black;
            }
        }
    }
}
