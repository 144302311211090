.pv-menu-main{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: inline;
    padding: .5rem 1rem;
    width: 7%;
    height: auto;
    transition: 1s;
    background-color: white;
    border-radius: .3rem;

    .file{
      display: inline;
      position: relative;
      cursor: pointer;
      width: 100%;
      
      .icon{
        align-items: center;
        display: flex;
        gap: .4rem;
        color: #125488;
      }
      
      input[type=file]{
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 0;
      }
    }
  }